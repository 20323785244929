import { C2CConsole } from "../Helper/C2CConsole";
import { LOCALSTORAGEKEY } from "../Helper/localStorageUtils";
import { cookiesDomainUrl } from "./APIList";

// we are saving only id token for communicating with sub domain. One of the submain is pem.
// may be in future we will add more as we follow the microservies structure.

const COOKIE_NAME = "cookie-name";
// setCookie will take from "useCookies" and passed as props here
const setCookieForUser = (id, setCookie) => {
  setCookie("id_token", id, {
    path: "/",
    domain: cookiesDomainUrl,
  });
};

export const setDynamicCookieForUser = (name, value, setCookie) => {
  setCookie(name, value, {
    path: "/",
    domain: cookiesDomainUrl,
  });
};

// removeCookie will take from "useCookies" and passed as props here
const removeCookieForUser = (removeCookie) => {
  C2CConsole.log("removeCookieForUser");
  removeCookie("id_token", { path: "/", domain: cookiesDomainUrl });
};

const cookiesLogicForAuth = (cookies) => {
  // console.log("cookies--->", cookies);
  if (
    cookies.id_token &&
    !localStorage.getItem(LOCALSTORAGEKEY.ID_TOKEN) &&
    (cookies.user_type === "doctor" || cookies.user_type === "patient")
  ) {
    console.log("cookies logic success");
    localStorage.setItem(LOCALSTORAGEKEY.ID_TOKEN, cookies.id_token);
    localStorage.setItem(LOCALSTORAGEKEY.USER_TYPE, cookies.user_type);
    localStorage.setItem(LOCALSTORAGEKEY.USER_ID, cookies.user_id);
    localStorage.setItem("last_login_date", cookies.last_login_date);
    localStorage.setItem(LOCALSTORAGEKEY.USER_NAME, cookies.user_name);
    localStorage.setItem("stay-login", cookies.stay_login);
    if (cookies.clinicId) {
      localStorage.setItem(LOCALSTORAGEKEY.CLINIC_ID, cookies.clinicId);
    }
    if (cookies.previousSelectedDoctors) {
      localStorage.setItem(
        LOCALSTORAGEKEY.PREVIOUS_SELECTED_DOCTORS,
        cookies.previousSelectedDoctors
      );
    }
    if (cookies.doctorRole) {
      localStorage.setItem(LOCALSTORAGEKEY.DOCTOR_ROLE, cookies.doctorRole);
    }

    // console.log(
    //   "cookies.previousSelectedDoctors inside cookie helper--->",
    //   cookies.previousSelectedDoctors
    // );
    if (cookies.user_type === "doctor") {
      localStorage.setItem("full_name", cookies.full_name);
      localStorage.setItem("specialization", cookies.specialization);
      localStorage.setItem("doctor_role_name", cookies.doctor_role_name);
      localStorage.setItem(
        "doctor_clinic_association",
        cookies.doctor_clinic_association
      );
      localStorage.setItem("doctorPhone", cookies.doctorPhone);
      localStorage.setItem("is_partial_doctor", false);
    }
    return;
  }
  console.log("cookies not success");
};

export {
  COOKIE_NAME,
  setCookieForUser,
  removeCookieForUser,
  cookiesLogicForAuth,
};
