/*
 * HomeReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from "immer";
import { VITALSKEY_MAP } from "../DashboardAppointmentsView/Constant";

import {
  TEST_SUCCESS,
  TEST_FAILED,
  ADD_CHIEF_COMPLAINT,
  UPDATE_CHIEF_COMPLAINT,
  ADD_DIAGNOSIS_ITEM,
  UPDATE_DIAGNOSIS_ITEM,
  UPDATE_PATIENT_DATA,
  UPDATE_DRUG_ITEM_DATA,
  CLEAR_DRUG_ITEM_DATA,
  ADD_NEW_DRUG_ITEM,
  UPDATE_EXAM_ITEM_DATA,
  CLEAR_EXAM_ITEM_DATA,
  ADD_NEW_EXAM_ITEM,
  PESCRIPTION_FORM_PROPERTY_UPDATE,
  DOCTOR_NOTES_UPDATE,
  VALIDATE_FORM,
  SET_USER_TOKEN,
  SET_USER_TYPE,
  SET_APPOINTMENT_DATA,
  PREVIEW_FORM_SUCCESS,
  SAVE_FORM_REJECTED,
  SAVE_FORM,
  SAVE_FORM_SUCCESS,
  SHARE_FORM_SUCCESS,
  LOAD_LAST_AUTOSAVED_SUCCESS,
  RESET_STATE,
  HIDE_PREVIEW_FORM,
  SET_NEW_DATE,
  DELETE_FOLLOW_UP_DATE,
  SHOW_PRESCRIPTION_MODAL,
  HIDE_PRESCRIPTION_MODAL,
  FETCH_DROP_DOWN_DETAIL_SUCCESS,
  REMOVE_DROP_DOWN_OPTION,
  DELETE_COMFORMATION_MODAL_SHOW,
  FETCH_DROP_DOWN_DETAIL_FAILED,
  DELETE_COMFORMATION_MODAL_CANCEL,
  REMOVE_DATA_SUCCESSFULL,
  SHARE_FORM,
  REMOVE_DRUGITEM,
  REMOVE_EXAMINITEM,
  PRESCRIPTION_TEMPLATE,
  LOAD_LAST_AUTOSAVED_FAILED,
  CHECK_MANUAL_RX_SHARED,
  UPDATE_PATIENT_VITALS,
  LOAD_PATIENT_VITALS,
  LOAD_PATIENT_VITALS_FAILED,
  PEM_SUGGESTED_KEY_FROM_RX,
  UPDATE_DRUG_SEARCH_PAGE_NO,
} from "./constants";

// The initial state of the App
import patientData from "./PatientInfoData";
export const initialState = {
  patient: patientData.getPatientData(),
  // patient: {
  //   height: "",
  //   weight: "",
  //   lmp: "",
  //   chiefComplaints: ["", ""],
  //   history: "",
  //   diagnosis: ["", ""],
  // },
  vitals: patientData.getVitalsInfo(),
  drugs: patientData.getDrugsData(),
  // drugs: [
  //   {
  //     drug: "",
  //     frequency: "",
  //     duration: "",
  //     instruction: "",
  //     freqUnit: "days",
  //   },
  //   {
  //     drug: "",
  //     frequency: "",
  //     duration: "",
  //     instruction: "",
  //     freqUnit: "days",
  //   },

  //   {
  //     drug: "",
  //     frequency: "",
  //     duration: "",
  //     instruction: "",
  //     freqUnit: "days",
  //   },
  // ],
  labFindings: patientData.getlabFindingsData(),
  // labFindings: [
  //   {
  //     test: "",
  //     instruction: "",
  //   },
  //   {
  //     test: "",
  //     instruction: "",
  //   },
  // ],
  nextFollowUpDate: patientData.getNextFollowUpDateData(),
  // nextFollowUpDate: "",
  suggested_investigations: patientData.getPrescriptionFormPropertyData(),
  verbalConsentCheckBox: true,
  saved: false,
  formErrorsArray: [],
  token: "",
  userType: "",
  appointmentData: { app_id: { id: "" } },
  viewPrescription: false,
  prescriptionUploaded: false,
  presciptionSaving: false,
  prescriptionSharing: false,
  disableUpload: true,
  showPrescriptionShareModal: false,
  dropDown: {
    selectState: [],
    isShowDropDown: null,
    isDeleteStatus: false,
  },
  doctor_notes: patientData.getDoctorNotes(),
  saveTemplate: false,
  templateName: "",
  isERxCreated: "",
  isManualRxCreated: "",
  prescription_saved: false,
  pemSuggestedKey: [],
  drugSearchPageNoAndMaxNoOfPages: {
    drugSearchPageNo: 1,
    maxNumberOfPages: null,
    clearListOnType: false,
  },
};

/* eslint-disable default-case, no-param-reassign */
const homeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TEST_SUCCESS:
        draft.stateItem = action.payload;
        break;
      case TEST_FAILED:
        draft.theOtherStateItem = action.payload;
        break;

      case ADD_CHIEF_COMPLAINT:
        draft.patient.chiefComplaints.push("");
        break;

      case UPDATE_CHIEF_COMPLAINT:
        draft.patient.chiefComplaints[action.payload.index] =
          action.payload.value;
        const pastValues = JSON.parse(localStorage.getItem("patientData"));
        let newValues;
        if (pastValues) {
          newValues = {
            ...pastValues,
            chiefComplaints: [...draft.patient.chiefComplaints],
          };
        } else {
          newValues = draft.patient.chiefComplaints;
        }
        localStorage.setItem("patientData", JSON.stringify(newValues));
        break;

      case ADD_DIAGNOSIS_ITEM:
        draft.patient.diagnosis.push("");
        break;

      case UPDATE_DIAGNOSIS_ITEM:
        draft.patient.diagnosis[action.payload.index] = action.payload.value;
        // localStorage.setItem('diagnosisData',JSON.stringify(draft.patient.diagnosis));
        const pastDiagnosisValues = JSON.parse(
          localStorage.getItem("patientData")
        );
        let newDiagnosisValues;
        if (pastDiagnosisValues) {
          newDiagnosisValues = {
            ...pastDiagnosisValues,
            diagnosis: [...draft.patient.diagnosis],
          };
        } else {
          newDiagnosisValues = draft.patient.diagnosis;
        }
        localStorage.setItem("patientData", JSON.stringify(newDiagnosisValues));
        break;

      case UPDATE_PATIENT_DATA:
        draft.patient[action.payload.name] = action.payload.value;
        const pastPatientValues = JSON.parse(
          localStorage.getItem("patientData")
        );
        let newPatientValues;
        if (pastPatientValues) {
          newPatientValues = { ...pastPatientValues, ...draft.patient };
        } else {
          newPatientValues = draft.patient;
        }
        localStorage.setItem("patientData", JSON.stringify(newPatientValues));
        break;

      case UPDATE_PATIENT_VITALS:
        draft.vitals[action.payload.name] = action.payload.value;
        draft.vitals[`${action.payload.name + "_unit"}`] = action.payload.unit;
        const pastPatientVitals = JSON.parse(
          localStorage.getItem("patientVitals")
        );
        let newPatientVitals;
        if (pastPatientVitals) {
          newPatientVitals = { ...pastPatientVitals, ...draft.vitals };
        } else {
          newPatientVitals = draft.vitals;
        }
        localStorage.setItem("patientVitals", JSON.stringify(newPatientVitals));
        break;
      case UPDATE_DRUG_ITEM_DATA:
        if (action.payload.name == "drug") {
          draft.drugs[action.payload.index][action.payload.name] =
            action.payload.value;
          draft.drugs[action.payload.index].drug_id = action.payload.drug_id
            ? action.payload.drug_id
            : "";
        } else {
          draft.drugs[action.payload.index][action.payload.name] =
            action.payload.value;
        }
        localStorage.setItem("drugItemData", JSON.stringify(draft.drugs));
        break;
      case UPDATE_DRUG_SEARCH_PAGE_NO:
        draft.drugSearchPageNoAndMaxNoOfPages = {
          drugSearchPageNo: action.payload?.drugSearchPageNo,
          maxNumberOfPages: action.payload?.maxNumberOfPages,
          clearListOnType: action.payload?.clearListOnType,
        };
        break;
      case CLEAR_DRUG_ITEM_DATA:
        draft.drugs[action.payload.index] = {
          drug: "",
          frequency: "",
          duration: "",
          instruction: "",
          freqUnit: "days",
        };
        break;
      case ADD_NEW_DRUG_ITEM:
        draft.drugs.push({
          drug: "",
          frequency: "",
          duration: "",
          instruction: "",
          freqUnit: "days",
        });
        break;
      case UPDATE_EXAM_ITEM_DATA:
        draft.labFindings[action.payload.index][action.payload.name] =
          action.payload.value;
        localStorage.setItem("examItemData", JSON.stringify(draft.labFindings));
        break;
      case CLEAR_EXAM_ITEM_DATA:
        draft.labFindings[action.payload.index] = {
          test: "",
          instruction: "",
        };
        break;
      case ADD_NEW_EXAM_ITEM:
        draft.labFindings.push({
          test: "",
          instruction: "",
        });
        break;
      case PESCRIPTION_FORM_PROPERTY_UPDATE:
        draft[action.payload.name] = action.payload.value;
        localStorage.setItem(
          "prescriptionFormProperty",
          draft["suggested_investigations"]
        );
        break;
      case DOCTOR_NOTES_UPDATE:
        draft.doctor_notes = action.payload.value;
        localStorage.setItem("doctorNotes", draft["doctor_notes"]);
        break;
      case SET_USER_TOKEN:
        draft.token = action.payload;
        break;
      case SET_USER_TYPE:
        draft.userType = action.payload;
        break;
      case SET_APPOINTMENT_DATA:
        draft.appointmentData = action.payload;
        break;
      case PREVIEW_FORM_SUCCESS:
        draft.viewPrescription = action.payload.viewPrescription;
        draft.prescriptionUploaded = action.payload.prescriptionUploaded;
        draft.pemSuggestedKey = fetchKeyWordforPEM(
          action.payload.viewPrescription
        );
        break;

      case SAVE_FORM:
        draft.presciptionSaving = true;
        break;

      case SHARE_FORM:
        draft.prescriptionSharing = true;
        break;

      case SAVE_FORM_SUCCESS:
        draft.presciptionSaving = false;
        draft.disableUpload = false;
        draft.prescription_saved = true;
        break;
      case SAVE_FORM_REJECTED:
        draft.presciptionSaving = false;
        draft.prescription_saved = true;
        break;
      case SHARE_FORM_SUCCESS:
        draft.prescriptionSharing = false;
        break;
      case LOAD_LAST_AUTOSAVED_SUCCESS:
        let { prescription } = action.payload;
        draft.patient = createPatientItem(prescription);
        draft.drugs = createDrugs(prescription);
        draft.labFindings = createLabFindings(prescription);
        draft.suggested_investigations =
          createsuggestedInvestigations(prescription);
        draft.doctor_notes = createdoctorNotes(prescription);
        draft.isERxCreated = prescription?.isERxCreated;
        let tempDate = new Date(action.payload.prescription.follow_up_date);
        draft.nextFollowUpDate = Number.isNaN(
          tempDate.setHours(tempDate.getHours() + 8)
        )
          ? ""
          : new Date(tempDate.setHours(tempDate.getHours() + 8));
        localStorage.setItem("patientData", JSON.stringify(draft.patient));
        localStorage.setItem("drugItemData", JSON.stringify(draft.drugs));
        localStorage.setItem("examItemData", JSON.stringify(draft.labFindings));
        localStorage.setItem(
          "followupData",
          JSON.stringify(draft.nextFollowUpDate)
        );
        localStorage.setItem(
          "prescriptionFormProperty",
          draft.suggested_investigations
        );
        localStorage.setItem("doctorNotes", draft.doctor_notes);
        break;
      case LOAD_LAST_AUTOSAVED_FAILED:
        draft.isERxCreated = false;
        draft.patient = {
          height: "",
          weight: "",
          lmp: "",
          chiefComplaints: ["", ""],
          history: "",
          diagnosis: ["", ""],
        };
        break;
      case LOAD_PATIENT_VITALS:
        let vitals = {};
        let vital =
          action.payload &&
          Object.keys(action.payload)?.forEach((key) => {
            // vitals[
            //   key.charAt(0).toUpperCase() + key.slice(1).replace(/_/i, " ")
            // ] = action.payload[key];
            vitals[VITALSKEY_MAP[key]] = action.payload[key];
          });
        draft.vitals = vitals;
        localStorage.setItem("patientVitals", JSON.stringify(vitals));
        break;
      case LOAD_PATIENT_VITALS_FAILED:
        let emptyVitals = {};
        draft.vitals = emptyVitals;
        localStorage.setItem("patientVitals", JSON.stringify(emptyVitals));
        break;
      case CHECK_MANUAL_RX_SHARED:
        draft.isManualRxCreated = action.payload;
        break;
      case RESET_STATE:
        Object.assign(draft, initialState);
        break;
      case HIDE_PREVIEW_FORM:
        draft.prescriptionUploaded = false;
        break;

      case SET_NEW_DATE:
        action.payload.setHours(action.payload.getHours() + 8);
        draft.nextFollowUpDate = action.payload;
        localStorage.setItem(
          "followupData",
          JSON.stringify(draft.nextFollowUpDate)
        );
        break;
      case DELETE_FOLLOW_UP_DATE:
        draft.nextFollowUpDate = "";
        break;
      case SHOW_PRESCRIPTION_MODAL:
        draft.showPrescriptionShareModal = true;
        break;
      case HIDE_PRESCRIPTION_MODAL:
        draft.showPrescriptionShareModal = false;

        break;
      case FETCH_DROP_DOWN_DETAIL_SUCCESS:
        draft.dropDown.selectState = action.payload.selectState;
        draft.dropDown.isShowDropDown = action.payload.isShowDropDown;
        draft.showPrescriptionShareModal = false;
        draft.dropDown.isDeleteStatus = false;
        draft.dropDown.deleteDetail = null;
        break;
      case FETCH_DROP_DOWN_DETAIL_FAILED:
        draft.dropDown.selectState = [];
        draft.dropDown.isShowDropDown = null;
        break;
      case REMOVE_DROP_DOWN_OPTION:
        draft.dropDown.selectState = [];
        draft.dropDown.isShowDropDown = null;
        break;
      case DELETE_COMFORMATION_MODAL_SHOW:
        draft.dropDown.selectState = [];
        draft.dropDown.isShowDropDown = null;
        draft.dropDown.isDeleteStatus = true;
        draft.dropDown.deleteDetail = action.payload;
        break;
      case DELETE_COMFORMATION_MODAL_CANCEL:
        draft.dropDown.selectState = [];
        draft.dropDown.isShowDropDown = null;
        draft.dropDown.isDeleteStatus = false;
        draft.dropDown.deleteDetail = null;
        break;
      case REMOVE_DATA_SUCCESSFULL:
        draft.dropDown.isDeleteStatus = false;
        draft.dropDown.deleteDetail = null;
        break;
      // case VALIDATE_FORM:
      //   let formErrorsArray = [];
      //   if (state.patient.chiefComplaints[0] == "")
      //     formErrorsArray.push({
      //       complaints: "At least one complaint is mandatory",
      //     });
      //   if (state.patient.diagnosis[0] == "")
      //     formErrorsArray.push({
      //       diagnosis: "At least one diagnosis is mandatory",
      //     });
      //   draft.formErrorsArray = formErrorsArray;
      //   break;

      case REMOVE_DRUGITEM:
        let myState = state.drugs.filter(
          (word, index) => index !== action.payload.index
        );
        draft.drugs = myState;
        break;

      case REMOVE_EXAMINITEM:
        let examin_state = state.labFindings.filter(
          (word, index) => index !== action.payload.index
        );
        if (examin_state.length > 0) {
          draft.labFindings = examin_state;
        } else {
          draft.labFindings = [
            {
              test: "",
              instruction: "",
            },
          ];
        }
        break;

      case PRESCRIPTION_TEMPLATE:
        draft.template_name = action.payload.value;
        localStorage.setItem("templateName", draft["template_name"]);
        break;
    }
  });

function createPatientItem(prescription) {
  let patientItem = {
    height: "",
    weight: "",
    lmp: "",
    chiefComplaints: ["", ""],
    history: "Relevant Points from History",
    diagnosis: ["", ""],
  };

  patientItem.height = parseInt(prescription.patient_height).toString();
  patientItem.weight = parseInt(prescription.patient_weight).toString();
  patientItem.lmp = prescription.lmp;
  patientItem.diagnosis = prescription.diagnosis?.split("<br>");
  patientItem.chiefComplaints = prescription.complaints?.split("<br>");
  patientItem.history = prescription.relevance_history;
  patientItem.prescription_id = prescription.prescription_id;
  return patientItem;
}

function createDrugs(prescription) {
  let drugs = [];
  prescription.drug_info.map((x) => {
    //prescription.medications.map
    let tempItem = {};
    tempItem.frequency = x.frequency;
    tempItem.instruction = x.instruction;
    tempItem.drug = x && x.drug_name ? x.drug_name : x && x.drug ? x.drug : ""; //x.drug;
    tempItem.duration = parseInt(x.duration);
    tempItem.drug_id = x?.drug_id;

    if (x.duration.includes("days")) tempItem.freqUnit = "days";
    if (x.duration.includes("weeks")) tempItem.freqUnit = "weeks";
    if (x.duration.includes("months")) tempItem.freqUnit = "months";
    if (
      !x.duration.includes("days") &&
      !x.duration.includes("weeks") &&
      !x.duration.includes("months")
    )
      tempItem.freqUnit = "days";

    drugs.push(tempItem);
  });
  return drugs;
}
function createLabFindings(prescription) {
  let labFindings = [];
  prescription.lab_results.map((x) => {
    let tempItem = {};
    tempItem.test = x.test;
    tempItem.instruction = x.instruction;
    labFindings.push(tempItem);
  });
  return labFindings;
}

function createsuggestedInvestigations(prescription) {
  return prescription.suggested_investigations;
}

function createdoctorNotes(prescription) {
  return prescription.doctor_notes;
}

function fetchKeyWordforPEM(prescription) {
  let prescriptionData = ["all"];
  if (prescription?.complaints && prescription?.complaints !== "<br>") {
    let complaintList = prescription.complaints
      .replaceAll("<br>", ",")
      .split(",");
    prescriptionData = [...prescriptionData, ...complaintList];
  }
  if (prescription?.diagnosis && prescription?.diagnosis !== "<br>") {
    let diagnosisList = prescription.diagnosis
      .replaceAll("<br>", ",")
      .split(",");
    prescriptionData = [...prescriptionData, ...diagnosisList];
  }
  if (prescription?.relevance_history) {
    let relevance_historyList = prescription.relevance_history.split(",");
    prescriptionData = [...prescriptionData, ...relevance_historyList];
  }
  prescriptionData = prescriptionData.map((item) => item.toLowerCase().trim());
  return prescriptionData;
}

export default homeReducer;
