export const PDF_DRUG_HEADERS = [
  {
    id: 1,
    title: "Drug Name",
    langTitle: "Drug Name",
    key: "drug_name",
    subtitle_key: "salt_composition",
    width: 20,
  },
  {
    id: 2,
    title: "Frequency",
    langTitle: "Frequency",
    key: [
      "quantity",
      "unit",
      "frequency_extended.title",
      "frequency_extended.details",
    ],
    width: 17,
  },
  { id: 3, title: "Period", langTitle: "Period", key: "duration", width: 16 },
  { id: 4, title: "Timing", langTitle: "Timing", key: "timing", width: 16 },

  {
    id: 5,
    title: "Instructions",
    langTitle: "Instructions",
    key: "instruction",
    width: 25,
  },
];
export const PDF_VACCINE_HEADERS = [
  {
    id: 1,
    title: "Vaccine Name",
    key: "name",
    width: 20,
  },
  {
    id: 2,
    title: "Vaccine Brand",
    key: "vaccine_brand_name",
    width: 25,
  },
  { id: 3, title: "Vaccine Date", key: "vaccinated_date", width: 25 },
  { id: 4, title: "Height/Weight/HC", key: "height_weight_hc", width: 25 },
];
export const PDF_DIAGNOSIS = "DIAGNOSIS";
export const PDF_REPORT_AND_EXAM = "PATIENT/REPORTS EXAMINATION";
