import React, { useState } from "react";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import DropDownSelector from "../UI Component/DropDownSelector/DropDownSelector";
import Spinner from "react-bootstrap/Spinner";
import { SettingsRemoteRounded } from "@material-ui/icons";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";

export default function DrugItemComponent(props) {
  const {
    Data,
    index,
    handleChange,
    clearRecord,
    getSerachPopulatedData,
    dropDown,
    dropDownSelected,
    handleDeletePrec,
    removeDrugItem,
    dispatchInitializeAutoSave,
    dispatchSetDrugSearchPageNoAndMaxPage,
    drugSearchPageNoAndMaxNoOfPages,
  } = props;
  const [selectedUnit, setSelectedUnit] = useState("days");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [show, setShow] = useState(false);
  const [pindex, setPIndex] = useState();
  const [exindex, setExamIndex] = useState();
  const [frequencyIndex, setFrequencyIndex] = useState();
  const [daysIndex, setDaysIndex] = useState();
  const [showFreqLoader, setFreqShow] = useState(false);

  const userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);

  const handleSerachData = (e) => {
    if (e.target.name === "drug") {
      dispatchSetDrugSearchPageNoAndMaxPage({
        ...drugSearchPageNoAndMaxNoOfPages,
        drugSearchPageNo: 1,
        maxNumberOfPages: 0,
        clearListOnType: true,
      });
      setPIndex(index);
      setExamIndex("");
      clearTimeout(typingTimeout);
      handleChange(e, index, "");
      if (e.target.value.length >= 1) {
        setShow(true);
        const search_field =
          e.target.name === "drug" ? "drug_name" : "instruction";
        const value = e.target.value;
        setTypingTimeout(
          setTimeout(() => {
            getSerachPopulatedData(
              {
                doctor_id: userId,
                search_field: search_field,
                search_text: value,
                user_type: "1",
                page_no: 1,
                page_size: 20,
              },
              search_field + "_" + index
            );
            setShow(false);
          }, 500)
        );
      } else {
        setShow(false);
        dropDownSelected();
      }
    } else {
      setPIndex("");
      setExamIndex(index);
      clearTimeout(typingTimeout);
      handleChange(e, index, "");
    }
  };

  const handleSelect = (item, name) => {
    handleChange(
      {
        target: { name, value: item.label },
      },
      index,
      item.drug_id ? item.drug_id : ""
    );
    dropDownSelected(item.label, index);
  };

  const handleDelete = (event, item, name) => {
    event && event.stopPropagation();
    const search_field = name;
    handleDeletePrec({
      doctor_id: userId,
      item_id: item.id,
      search_field,
      user_type: "1",
    });
  };

  const handleOnScrollDropdown = (e) => {
    let bottom =
      e.target.scrollHeight - Math.round(e.target.scrollTop) ===
      e.target.clientHeight;
    if (
      e.target.scrollHeight - Math.round(e.target.scrollTop) + 1 ===
      e.target.clientHeight
    ) {
      bottom = true;
    }
    if (
      e.target.scrollHeight - Math.round(e.target.scrollTop) - 1 ===
      e.target.clientHeight
    ) {
      bottom = true;
    }
    if (bottom) {
      if (
        drugSearchPageNoAndMaxNoOfPages?.drugSearchPageNo <
        drugSearchPageNoAndMaxNoOfPages?.maxNumberOfPages
      ) {
        dispatchSetDrugSearchPageNoAndMaxPage({
          ...drugSearchPageNoAndMaxNoOfPages,
          drugSearchPageNo:
            drugSearchPageNoAndMaxNoOfPages?.drugSearchPageNo + 1,
          clearListOnType: false,
        });
        let drugName = Data?.drug;
        if (drugName.length >= 1) {
          setShow(true);
          const search_field = "drug_name";
          const value = drugName;
          setTypingTimeout(
            setTimeout(() => {
              getSerachPopulatedData(
                {
                  doctor_id: userId,
                  search_field: search_field,
                  search_text: value,
                  user_type: "1",
                  page_no:
                    drugSearchPageNoAndMaxNoOfPages?.drugSearchPageNo + 1,
                  page_size: 20,
                },
                search_field + "_" + index
              );
              setShow(false);
            }, 500)
          );
        } else {
          setShow(false);
          dropDownSelected();
        }
      }
    }
  };

  return (
    <div
      className="drug-card mb-4 shadow-sm rounded prescription_card"
      key={index}
    >
      <div className="d-flex justify-content-between">
        <div style={{ position: "relative", bottom: "24px" }}>
          <Badge
            className="rounded-circle p-2 m-2 shadow bg-white rounded"
            variant="secondary"
            style={{
              cursor: "pointer",
              color: "black",
            }}
          >
            {index + 1 >= 10 ? index + 1 : "0" + Number(index + 1)}
          </Badge>
        </div>
        <div style={{ display: "flex" }}>
          <Badge
            onClick={() => clearRecord(index)}
            className="m-2 float-right" //"rounded-circle m-2 float-right"
            variant="light"
            style={{
              cursor: "pointer",
              color: "#17BAE0",
              fontSize: "12px",
              background: "none",
            }}
          >
            Clear
          </Badge>
          <Badge
            onClick={() => {
              removeDrugItem(index);
              dispatchInitializeAutoSave();
            }}
            className="m-2 float-right" //rounded-circle
            variant="light"
            style={{
              cursor: "pointer",
              color: "#17BAE0",
              fontSize: "14px",
              height: "fit-content",
              backgroundColor: "white",
            }}
          >
            <i
              class="fa fa-trash"
              aria-hidden="true"
              style={{ color: "#dc3545" }}
            ></i>
          </Badge>
        </div>
      </div>
      <li
        id={"li_medication_" + index}
        name={"li_medication_" + index + "[]"}
        style={{
          padding: "10px", //"15px"
          marginBottom: "10px",
        }}
      >
        <div className="row mb-2" style={{ marginRight: "-24px" }}>
          <div className="col-md-12 mb-2 " style={{ color: "#6f747a" }}>
            <DropDownSelector
              placeholder={"Drug"}
              value={Data.drug}
              handleSearch={(value) => {
                handleSerachData(value);
              }} //handleSerachData(value, index)
              name={"drug"}
              id={`drug_${index}`}
              options={dropDown.selectState}
              isShowDropDown={
                "drug_name_" + index === dropDown.isShowDropDown ? true : false
              }
              handleSelect={(item) => handleSelect(item, "drug")}
              handleDelete={(event, item) =>
                handleDelete(event, item, "drug_name")
              }
              show={show}
              indx={index}
              pindex={pindex}
              loading={true}
              handleOnScrollDropdown={handleOnScrollDropdown}
            />
          </div>
          <div className="col-md-12 mb-2 input_div" style={{ border: "none" }}>
            <input
              type="text"
              maxLength="30"
              className="form-control shadow-sm"
              placeholder="Frequency"
              style={{
                fontSize: "14px",
                height: "48px",
              }}
              id={"medication_frequency_" + index}
              name="frequency"
              value={Data.frequency}
              onChange={(e) => {
                setFreqShow(true);
                setDaysIndex("");
                setFrequencyIndex(index);
                handleChange(e, index, "");
                setTimeout(function () {
                  setFreqShow(false);
                }, 500);
              }}
            />
            {showFreqLoader && index === frequencyIndex ? (
              <Spinner animation="border" className="frequency_spinner" />
            ) : null}
          </div>
          <Col className="mb-2">
            <Row xs={1} xl={2}>
              <Col
                className="input_div"
                style={{
                  color: "#6f747a",
                  display: "flex",
                  border: "none",
                }}
              >
                <input
                  maxLength="30"
                  type="number"
                  placeholder="For how many"
                  style={{
                    fontSize: "14px",
                    height: "48px",
                  }}
                  className="form-control shadow-sm"
                  id={"medication_duration_" + index}
                  name="duration"
                  value={Data.duration}
                  onKeyDown={(e) => {
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault();
                  }}
                  onChange={(e) => {
                    setFreqShow(true);
                    setFrequencyIndex("");
                    setDaysIndex(index);
                    handleChange(e, index, "");
                    setTimeout(function () {
                      setFreqShow(false);
                    }, 500);
                  }}
                />
                {showFreqLoader && index === daysIndex ? (
                  <Spinner animation="border" className="frequency_spinner" />
                ) : null}
              </Col>
              <Col>
                <ToggleButtonGroup
                  size={"sm"}
                  type="radio"
                  className="toggleButonGroup"
                  value={Data.freqUnit}
                  name="freqUnit"
                  onChange={(val) => {
                    setSelectedUnit(val);
                    handleChange(
                      { target: { value: val, name: "freqUnit" } },
                      index,
                      ""
                    );
                  }}
                >
                  <ToggleButton className="toggleButtonItem" value={"days"}>
                    Days
                  </ToggleButton>
                  <ToggleButton className="toggleButtonItem" value={"weeks"}>
                    Weeks
                  </ToggleButton>
                  <ToggleButton className="toggleButtonItem" value={"months"}>
                    Months
                  </ToggleButton>
                </ToggleButtonGroup>
              </Col>
            </Row>
          </Col>
          <div className="col-md-12 mb-2 " style={{ color: "#6f747a" }}></div>
          <div className="col-md-12 ">
            <DropDownSelector
              value={Data.instruction}
              handleSearch={(value) => {
                handleSerachData(value);
              }}
              // handleSerachData(value, index)
              name={"instruction"}
              id={`instruction_${index}`}
              options={dropDown.selectState}
              isShowDropDown={
                "instruction_" + index === dropDown.isShowDropDown
                  ? true
                  : false
              }
              handleSelect={(item) => handleSelect(item, "instruction")}
              handleDelete={(event, item) =>
                handleDelete(event, item, "instruction")
              }
              show={show}
              indx={index}
              pindex={exindex}
              loading={true}
              placeholder={"Instructions"}
            />
          </div>
        </div>
      </li>
    </div>
  );
}
