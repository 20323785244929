//Sage file for the Patients Import container

import { put, select, takeLatest, all, delay } from "redux-saga/effects";
import {
  INITALIZE_AUTOSAVE,
  LOAD_LAST_AUTOSAVED,
  PREVIEW_FORM,
  PREVIEW_FORM_FAILED,
  PREVIEW_FORM_SUCCESS,
  SAVE_FORM,
  SAVE_FORM_REJECTED,
  SAVE_FORM_SUCCESS,
  SHARE_FORM,
  SHARE_FORM_FAILED,
  SHARE_FORM_SUCCESS,
  LOAD_LAST_AUTOSAVED_FAILED,
  LOAD_LAST_AUTOSAVED_SUCCESS,
  SHOW_PRESCRIPTION_MODAL,
  FETCH_PRESCRIPTION_DETAILS,
  FETCH_DROP_DOWN_DETAIL_SUCCESS,
  FETCH_DROP_DOWN_DETAIL_FAILED,
  DELETE_COMFORMATION_MODAL_SUCCESS,
  REMOVE_DATA_SUCCESSFULL,
  REMOVE_DATA_FAILED,
  GET_PREVIOUS_PRESCRIPTION,
  GET_SELECTED_TEMPLATE,
  FETCH_DRUG_ON_SEARCH,
  GET_VITALS,
  LOAD_PATIENT_VITALS,
  LOAD_PATIENT_VITALS_FAILED,
  SAVE_PATIENT_VITALS_SUCCESS,
  SAVE_PATIENT_VITALS_FAILED,
  UPDATE_DRUG_SEARCH_PAGE_NO,
} from "./constants";
import swal from "sweetalert";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../Util/Helper/C2CConsole";
import { setDrugSearchPageNoAndMaxPage } from "./actions";

const getData = (state) => state.prescriptionComponent;
export function* saveFunction(action) {
  try {
    let data = yield select(getData);
    let formErrorsArray = [];
    if (data.patient.chiefComplaints[0] === "")
      formErrorsArray.push({
        error: "At least one complaint is mandatory",
      });
    // if (data.patient.diagnosis[0] === '')
    // 	formErrorsArray.push({
    // 		error: 'At least one diagnosis is mandatory'
    // 	});
    if (formErrorsArray.length > 0) {
      if (action.payload === "autosave") {
        yield put({ type: SAVE_FORM_REJECTED });
        return;
      }
      swal({
        title: "OOPS",
        text: formErrorsArray[0].error,
        icon: "error",
      });
      yield put({ type: SAVE_FORM_REJECTED });
      return;
    }

    let modifiedDrugsData = [];
    if (data.drugs?.length > 0) {
      data.drugs.forEach((x) => {
        if (
          x.drug === "" &&
          x.duration === "" &&
          x.frequency === "" &&
          x.instruction === ""
        )
          return;
        let tempRow = Object.assign({}, x);
        tempRow.duration = tempRow.duration + " " + tempRow.freqUnit;
        if (tempRow.drug_id) {
          delete tempRow.drug;
          tempRow.drug_id = tempRow.drug_id ? tempRow.drug_id : ""; //"09bad1e4-325f-47af-b7c4-962980d4e877"
        }
        modifiedDrugsData.push(tempRow);
      });
    } else {
      modifiedDrugsData.push({
        drug: "",
        duration: "",
        frequency: "",
        instruction: "",
      });
    }

    let dontSendNotification = true;
    let sharePrescription = false;

    if (action.type === SHARE_FORM) {
      dontSendNotification = false;
      sharePrescription = true;
    }

    if (
      action.type === SAVE_FORM &&
      action.payload &&
      action.payload === "regularSave"
    ) {
      dontSendNotification = false;
    }

    let saveVitals = yield afterLoginApiAxios.post(
      "/api/v1/doctor/add-edit-vitals",
      {
        appointment_id: data.appointmentData.app_id.id,
        user_type: localStorage.getItem("user_type"),
        user_id: localStorage.getItem(LOCALSTORAGEKEY.USER_ID),
        height: data.vitals.Height ?? "",
        weight: data.vitals.Weight ?? "",
        weight_unit: data.vitals.Weight_unit ?? "",
        height_unit: data.vitals.Height_unit ?? "",
        bp_systolic: data.vitals["BP Systolic"] ?? "",
        bp_diastolic: data.vitals["BP Diastolic"] ?? "",
        temperature: data.vitals.Temperature ?? "",
        temperature_unit: data.vitals.Temperature_unit ?? "",
        spo2: data.vitals["SPO2"] ?? "",
        random_glucose: data.vitals["Random Glucose"] ?? "",
        random_glucose_unit: data.vitals["Random Glucose_unit"] ?? "",
        pulse_rate: data.vitals["Pulse Rate"] ?? "",
        respiration_rate: data.vitals["Respiration Rate"] ?? "",
        lmp: data.vitals.LMP ?? "",
      },
      {
        headers: {
          Authorization: data.token,
        },
      }
    );
    let response1 = yield afterLoginApiAxios.post(
      "doctor/add-edit-e-prescription",
      {
        appointment_id: data.appointmentData.app_id.id,
        complaints: data.patient.chiefComplaints.join("<br>"),
        diagnosis: data.patient.diagnosis.join("<br>"),
        doctor_recommendation: "doctor_recommendation",
        lab_results: data.labFindings,
        // patient_height: data.patient.height, /// revert to this to update from patient
        // patient_weight: data.patient.weight,
        user_type: localStorage.getItem("user_type"),
        user_id: localStorage.getItem(LOCALSTORAGEKEY.USER_ID),
        height: data.vitals.Height ?? "",
        weight: data.vitals.Weight ?? "",
        weight_unit: data.vitals.Weight_unit ?? "",
        height_unit: data.vitals.Height_unit ?? "",
        bp_systolic: data.vitals["BP Systolic"] ?? "",
        bp_diastolic: data.vitals["BP Diastolic"] ?? "",
        temperature: data.vitals.Temperature ?? "",
        temperature_unit: data.vitals.Temperature_unit ?? "",
        spo2: data.vitals["SPO2"] ?? "",
        random_glucose: data.vitals["Random Glucose"] ?? "",
        random_glucose_unit: data.vitals["Random Glucose_unit"] ?? "",
        pulse_rate: data.vitals["Pulse Rate"] ?? "",
        respiration_rate: data.vitals["Respiration Rate"] ?? "",
        lmp: data.vitals.LMP ?? "",
        relevance_history: data.patient.history,
        suggested_investigations: data.suggested_investigations
          ? data.suggested_investigations
          : "",
        medication: modifiedDrugsData,
        finalize: false,
        follow_up_date: data.nextFollowUpDate,
        auto_save: dontSendNotification, //autosave flag to avoid sms on backend
        share_prescription: sharePrescription,
        doctor_notes: data.doctor_notes ? data.doctor_notes : "",
        template_name: data.template_name?.trim(),
        prescription_status: sharePrescription
          ? "shared"
          : data.isERxCreated || data.isManualRxCreated
          ? "edited"
          : "created",
        enable_follow_up: localStorage.getItem(
          LOCALSTORAGEKEY.ENABLE_LEADS_DASHBOARD
        ),
      },
      {
        headers: {
          Authorization: data.token,
        },
      }
    );

    if (action.type === SHARE_FORM) {
      yield put({ type: SHARE_FORM_SUCCESS });
      yield put({ type: SHOW_PRESCRIPTION_MODAL });
      return;
    }

    yield put({ type: SAVE_FORM_SUCCESS });

    if (action.payload === "regularSave") {
      yield put({ type: PREVIEW_FORM });
    }

    // yield put(testSuccess("Dipanshu"));
  } catch (err) {
    // yield put(testFailed(err));
  }
}
export function* getPatientVitals(action) {
  try {
    let data = yield select(getData);
    let response = yield afterLoginApiAxios.post(
      "/api/v1/doctor/get-vitals",
      {
        appointment_id: action.payload,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data.token,
        },
      }
    );
    if (response.data.statusCode === 200) {
      yield put({
        type: LOAD_PATIENT_VITALS,
        payload: response.data.data[0],
      });
    } else if (response.data.statusCode === 504) {
      yield put({
        type: LOAD_PATIENT_VITALS_FAILED,
      });
    }
  } catch (error) {
    C2CConsole.error(error);
  }
}
export function* previewForm() {
  try {
    let data = yield select(getData);
    let response = yield afterLoginApiAxios.post(
      "/doctor/get-prescription-rev1", //'/doctor/get-prescription',
      {
        appointment_id: data.appointmentData.app_id.id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data.token,
        },
      }
    );

    if (response.data.statusCode === 200) {
      yield put({
        type: LOAD_LAST_AUTOSAVED_SUCCESS,
        payload: {
          prescription: response.data.prescription_data.textual,
        },
      });
      yield put({
        type: PREVIEW_FORM_SUCCESS,
        payload: {
          viewPrescription: response.data.prescription_data.textual,
          prescriptionUploaded: true,
        },
      });
    } else {
      yield put({ type: SAVE_FORM_REJECTED });
      yield put({ type: PREVIEW_FORM_FAILED });
    }
  } catch (error) {
    C2CConsole.error(error);
  }
}

export function* shareFormHandler() {
  try {
    let data = yield select(getData);
    let response = yield afterLoginApiAxios.post(
      "doctor/upload-prescription",
      {
        appointment_id: data.appointmentData.app_id.id,
        prescriptions: data.viewPrescription.prescription_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data.token,
        },
      }
    );

    if (response.data.statusCode === 200) {
      yield put({
        type: SHARE_FORM_SUCCESS,
      });
    } else {
      yield put({
        type: SHARE_FORM_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: SHARE_FORM_FAILED,
    });
    C2CConsole.error(error);
  }
}

export function* autosaveSaga_LONG() {
  while (true) {
    yield put({ type: SAVE_FORM, payload: "autosave" });
    yield delay(60000);
  }
}

export function* loadLastSavedState(action) {
  try {
    let data = yield select(getData);
    let response = yield afterLoginApiAxios.post(
      "/doctor/get-prescription-rev1", //'/doctor/get-prescription',
      {
        appointment_id: action.payload,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data.token,
        },
      }
    );

    if (response.data.statusCode === 200) {
      C2CConsole.log("");
      yield put({
        type: LOAD_LAST_AUTOSAVED_SUCCESS,
        payload: {
          prescription: {
            ...response.data.prescription_data.textual,
            isERxCreated: true,
          },
        },
      });
    } else {
      yield put({
        type: LOAD_LAST_AUTOSAVED_FAILED,
      });
    }
  } catch (error) {
    C2CConsole.error(error);
  }
}

export function* fetchAutoFiledData(action) {
  try {
    let data = yield select(getData);
    let response = yield afterLoginApiAxios.post(
      "doctor/search-string-for-autofill",
      {
        ...action.payload.value,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data.token,
        },
      }
    );

    if (response && response.data && response.data.status === 200) {
      yield put({
        type: FETCH_DROP_DOWN_DETAIL_SUCCESS,
        payload: {
          selectState: response.data.result_items || [],
          isShowDropDown: action.payload.isShowDropDown,
        },
      });
    } else {
      yield put({ type: FETCH_DROP_DOWN_DETAIL_FAILED });
    }
  } catch (error) {
    C2CConsole.error("error=>", error);
  }
}

export function* fetchDrugOnSearch(action) {
  try {
    let data = yield select(getData);

    let response = yield afterLoginApiAxios({
      method: "get",
      url: "api/v1/clinic/search-drug-for-autofill-rev1",
      params: action.params?.value ?? "",
      headers: {
        "Content-Type": "application/json",
        Authorization: data.token,
      },
    });
    if (response && response.data && response.data.statusCode === 200) {
      let drugs_from_database;
      if (response.data?.data.length > 0) {
        let count = response.data?.total_count;
        let maxCount = Math.ceil(count / 20);
        yield put({
          type: UPDATE_DRUG_SEARCH_PAGE_NO,
          payload: {
            ...data?.drugSearchPageNoAndMaxNoOfPages,
            maxNumberOfPages: maxCount,
          },
        });
        let data_db = [];
        response.data.data.map((data, indx) => {
          let obj = {};
          if (data?._source?.source === "master") {
            obj["drug_id"] = data?._id;
          } else {
            obj["id"] = data?._id;
            obj["doctor_drug_id"] = data?._id;
          }
          obj["drug_name"] = data?._source["drug_name"];
          obj["label"] = data?._source["drug_name"];
          obj["packaging"] = data?._source["packaging"];
          obj["salt_composition"] = data?._source["salt_composition"];
          data_db.push(obj);
        });
        drugs_from_database = data_db;
      } else {
        drugs_from_database = [];
      }
      let previousList = data?.drugSearchPageNoAndMaxNoOfPages?.clearListOnType
        ? []
        : [...data?.dropDown?.selectState];
      let total_data = [...previousList, ...drugs_from_database];
      yield put({
        type: FETCH_DROP_DOWN_DETAIL_SUCCESS,
        payload: {
          selectState: total_data || [], //response.data.result_items
          isShowDropDown: action.payload.isShowDropDown,
        },
      });
    } else {
      yield put({ type: FETCH_DROP_DOWN_DETAIL_FAILED });
    }
  } catch (error) {
    C2CConsole.error("error=>", error);
  }
}

export function* deleteAutoFiledData() {
  try {
    let data = yield select(getData);
    let response = yield afterLoginApiAxios.post(
      "doctor/delete-search-item",
      {
        ...data.dropDown.deleteDetail,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data.token,
        },
      }
    );

    if (response && response.data && response.data.statusCode === 200) {
      yield put({
        type: REMOVE_DATA_SUCCESSFULL,
        payload: {},
      });
    } else {
      yield put({ type: REMOVE_DATA_FAILED });
    }
  } catch (error) {
    C2CConsole.error("error=>", error);
  }
}

export function* getPreviousPrescription(action) {
  try {
    let data = yield select(getData);
    let response = yield afterLoginApiAxios.post(
      "/doctor/get-prescription-previous-rev1", ///get-prescription-previous
      {
        saved: action.payload.saved,
        // appointment_id: action.payload
        appointment_id: action.payload.appointment_id,
        prescription_id: action.payload.prescription_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data.token,
        },
      }
    );

    if (response.data.statusCode === 200) {
      if (response.data?.prescription_data?.textual) {
        response.data.prescription_data.textual["follow_up_date"] = "";
        yield put({
          type: LOAD_LAST_AUTOSAVED_SUCCESS,
          payload: {
            prescription: {
              ...response.data.prescription_data.textual,
              isERxCreated: data?.isERxCreated,
            },
          },
        });
      }
    }
  } catch (error) {
    C2CConsole.error(error);
  }
}

export function* getSelectedTemplate(action) {
  try {
    let data = yield select(getData);
    let response = yield afterLoginApiAxios.post(
      "/doctor/get-erx-template-data", ///get-prescription-previous
      {
        prescription_id: action.payload.prescription_id,
        template_name: action.payload.template_name,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data.token,
        },
      }
    );

    if (response.data.statusCode === 200) {
      let responseData = response.data.prescription_data.textual;
      let dataObj = {
        complaints: responseData.complaints,
        // medications: responseData.medications,
        drug_info: responseData.drug_info,
        patient_weight: responseData.patient_weight,
        patient_height: responseData.patient_height,
        // drug_info: responseData.medications,
        lab_results: responseData.lab_results,
        suggested_investigations:
          responseData.suggested_investigations?.replaceAll("<br>", "\n"),
        diagnosis: responseData.diagnosis,
        isERxCreated: data.isERxCreated,
      };
      yield put({
        type: LOAD_LAST_AUTOSAVED_SUCCESS,
        payload: {
          prescription: dataObj, //response.data.prescription_data.textual,
        },
      });
      yield put({
        type: LOAD_PATIENT_VITALS,
        payload: response.data?.prescription_data?.textual?.vitals[0],
      });
    }
  } catch (error) {
    C2CConsole.error(error);
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export default function* githubData() {
  // Watches for TEST actions and calls testMethod when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount

  const action = yield all([
    takeLatest(SAVE_FORM, saveFunction),
    takeLatest(LOAD_LAST_AUTOSAVED, loadLastSavedState),
    takeLatest(PREVIEW_FORM, previewForm),
    takeLatest(SHARE_FORM, saveFunction),
    takeLatest(INITALIZE_AUTOSAVE, autosaveSaga_LONG),
    takeLatest(FETCH_PRESCRIPTION_DETAILS, fetchAutoFiledData),
    takeLatest(FETCH_DRUG_ON_SEARCH, fetchDrugOnSearch),
    takeLatest(DELETE_COMFORMATION_MODAL_SUCCESS, deleteAutoFiledData),
    takeLatest(GET_PREVIOUS_PRESCRIPTION, getPreviousPrescription),
    takeLatest(GET_SELECTED_TEMPLATE, getSelectedTemplate),
    takeLatest(GET_VITALS, getPatientVitals),
  ]);
}
